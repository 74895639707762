
import pageViewGTM from '@/mixins/pageViewGTM'
import seoMixin from '@/mixins/seo'

import { useLotteryStore } from '~/stores/lottery'
import { mapGetters, mapMutations } from 'vuex'
import { useFeatureMGMStore } from '~/stores/feature-mgm'
import { useDcmStore } from '~/stores/dcm'
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'
import { usePromoCodeStore } from '@/stores/promocode'
import { rtbAnalytics } from '@/utilities'
import { useCustomer } from '@/stores/customer'
export default {
  name: 'MarketPlaceLanding',
  mixins: [pageViewGTM, seoMixin],
  layout: 'with-footer',
  fetchOnServer: true,
  setup() {
    const PromoCodeStore = usePromoCodeStore()
    const LotteryStore = useLotteryStore()
    const marketStore = useMarketStore()
    const dcmStore = useDcmStore()
    const featureMGMStore = useFeatureMGMStore()
    const uiStore = useUiStore()
    const customerStore = useCustomer()
    return {
      featureMGMStore,
      dcmStore,
      PromoCodeStore,
      LotteryStore,
      marketStore,
      uiStore,
      customerStore,
    }
  },
  async fetch() {
    const requests = [
      await this.featureMGMStore.getFeatures(),
      await this.dcmStore.getHeaderMarketInfo(),
      await this.LotteryStore.GET_LOTTERY(),
    ]
    if (!this.dcmStore.smart_category?.length) {
      requests.push(this.dcmStore.getSmartCategory())
    }
    await Promise.allSettled(requests)
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
  },
  async mounted() {
    await this.customerStore.checkSingleId(this.$route)
    if (this.isAuthenticated) {
      await this.PromoCodeStore.GET_CUSTOMER_PACKAGE()
      await this.openLotterySubscribe()
    }
    if (!this.marketStore.minInstallmentAmount) {
      await this.marketStore.getMinInstallmentAmount()
    }
    rtbAnalytics(this.$i18n.locale, {
      eventType: 'home',
    })
  },
  methods: {
    async openLotterySubscribe() {
      await this.LotteryStore.GET_LOTTERY_POPUP_DATE()
      if (
        Array.isArray(this.LotteryStore.dataPopup?.details) &&
        this.LotteryStore.dataPopup?.details?.length > 0
      ) {
        this.uiStore.isPopupLotterySubscribe = true
      }
    },
  },
}
