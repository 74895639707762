
// import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'MPLandingLoyatlyProgram',
  components: {
    // LoyaltyPartners,
  },
  // async fetch() {
  //   await this.GET_MARKETING_NAMES({
  //     per_page: 5,
  //     include_fields:
  //       'cashback_percentage,categories,parent_id,is_coming_soon,name,id,logo',
  //   }).catch(error => {
  //     console.log(error)
  //   })
  // },
  computed: {
    // ...mapGetters({
    //   marketing_names: 'catalog/marketing_names',
    // }),
    locale() {
      return this.$i18n.locale
    },
    loyaltyProgramSrc() {
      return `images/landing/loyalty-program.webp`
    },
    loyaltyProgramSrcWebp() {
      return `images/landing/loyalty-program.webp`
    },
  },
  // methods: {
  //   ...mapActions({
  //     GET_MARKETING_NAMES: 'catalog/GET_MARKETING_NAMES',
  //   }),
  // },
}
