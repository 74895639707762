
export default {
  name: 'MPProductsList',
  props: {
    hasAds: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: Infinity,
    },
    source: {
      type: String,
      default: '',
    },
    customView: {
      type: String,
      default: '',
    },
    listName: {
      type: String,
      default: null,
    },
  },
}
