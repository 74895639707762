
import { mapGetters } from 'vuex'
import { mapWritableState } from 'pinia'
import { useUiStore } from '@/stores/ui'
import { useCustomer } from '~/stores/customer'
import { useFeatureMGMStore } from '~/stores/feature-mgm'
import { useMarketStore } from '~/stores/market'
import { useCheckoutCredit } from '@/stores/checkout_credit'
import { useDcmStore } from '@/stores/dcm'

export default {
  name: 'CreditLine',
  props: {
    source: {
      type: String,
      default: '',
    },
  },
  setup() {
    const checkoutCredit = useCheckoutCredit()
    const dcmStore = useDcmStore()
    return { checkoutCredit, dcmStore }
  },

  computed: {
    ...mapWritableState(useCustomer, ['userProfile']),
    ...mapWritableState(useFeatureMGMStore, ['pre_scoring_banner']),
    ...mapWritableState(useMarketStore, ['selfCategory']),
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    ...mapWritableState(useUiStore, ['creditLineBanner']),
    getSourse() {
      if (this.source) return this.source
      if (this.$route.name?.includes('index')) return 'web_main'
      if (this.$route.name?.includes('categories')) {
        return this.selfCategory?.data?.view_mode === 'products'
          ? 'web_category'
          : 'web_catalogue'
      }
      if (this.$route.name?.includes('product')) return 'web_item'
      if (this.$route.name?.includes('cart')) return 'web_cart'
      if (this.$route.name?.includes('checkout')) return 'web_checkout'
      if (this.$route.name?.includes('brands')) return 'web_brand'
      if (this.$route.name?.includes('tags')) return 'web_tag'
      if (this.$route.name?.includes('merchant')) return 'web_merchant'
      if (this.$route.name?.includes('search')) return 'web_search'
      return 'web_category'
    },
    creditValue() {
      try {
        if (this.pre_scoring_banner.amount) {
          return +this.pre_scoring_banner.amount
        } else {
          return false
        }
      } catch (e) {
        console.error(e)
        return false
      }
    },
    getShowBanner() {
      return this.creditLineBanner
    },
    imagesBanner() {
      return this.dcmStore?.premiumSubscriptionPotentialBenefits
    },
  },
  watch: {
    userProfile(val, oldval) {
      if (!oldval && val) {
        this.getBannerCredit()
      }
    },
  },
  async mounted() {
    if (this.userProfile) {
      this.getBannerCredit()
    }
  },

  methods: {
    async getBannerCredit() {
      const featureMGMStore = useFeatureMGMStore()
      await featureMGMStore.getPreScoringBanner()
      if (this.pre_scoring_banner.amount) {
        this.creditLineBanner = true
      }
    },
    setEvent(is_bank) {
      this.checkoutCredit.sourceScrCreditApplication = this.source
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'banner_credit_line_click',
          is_bank_lead: is_bank,
          source: this.getSourse,
        })
      }
    },
    close() {
      this.creditLineBanner = false
    },
  },
}
