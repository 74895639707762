
import { useMarketStore } from '~/stores/market'
import { INCLUDE_FIELDS } from '@/constants'
import { useCustomer } from '@/stores/customer'
import { ref, useContext } from '@nuxtjs/composition-api'
import startObserverLoading from '@/mixins/startObserverLoading'

export default {
  name: 'MPPersonalRecommendations',
  mixins: [startObserverLoading],

  props: {
    isProduct: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
      default: null,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    const { $device } = useContext()
    const totalSlidesCount = ref(15)
    let slidePerPage = 0
    let keenSliderOptions = {}
    if ($device && $device.isMobile && !$device.isCrawler) {
      slidePerPage = 2
      keenSliderOptions = {
        slides: { perView: slidePerPage, spacing: 8 },
        rubberband: false,
      }
    } else if ($device && $device.isTablet && !$device.isCrawler) {
      slidePerPage = 3
      keenSliderOptions = {
        rubberband: false,
        breakpoints: {
          '(max-width: 768px)': {
            slides: { perView: 2, spacing: 8 }, // Up to 768px
          },
          '(min-width: 769px) and (max-width: 1000px)': {
            slides: { perView: slidePerPage, spacing: 8 }, // From 769px to 1000px
          },
          '(min-width: 1001px)': {
            slides: { perView: slidePerPage + 1, spacing: 8 }, // Greater than 1000px
          },
        },
      }
    } else if ($device && $device.isDesktop && !$device.isCrawler) {
      slidePerPage = 5

      keenSliderOptions = {
        breakpoints: {
          '(orientation: portrait)': {
            slides: { perView: 3, spacing: 8 },
          },
          '(orientation: portrait) and (min-width: 1024px)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(orientation: landscape)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(orientation: landscape) and (min-width: 1200px)': {
            slides: { perView: 5, spacing: 8 },
          },
        },
        slides: { perView: slidePerPage, spacing: 8 },
        rubberband: false,
      }
    }
    return {
      marketStore,
      keenSliderOptions,
      totalSlidesCount,
      slidePerPage,
      customerStore,
    }
  },
  data() {
    return {
      isLoading: false,
      waitingToLoad: true,
    }
  },

  computed: {
    hasProducts() {
      return !!this.marketStore.personalRecommendationsProducts.length
    },
  },
  mounted() {
    this.startObserverLoading(
      this.$refs.MPPersonalRecommendationsRef,
      this.startLoadingComponentPersonalRecommendations,
    )
  },
  methods: {
    async startLoadingComponentPersonalRecommendations() {
      if (this.loadOnce) return
      if (
        this.$auth.loggedIn &&
        this.$auth.$storage.getUniversal('user_ext_id')
      ) {
        const idsRecommendations =
          await this.marketStore.getPersonalRecommendations()
        if (
          idsRecommendations?.length &&
          idsRecommendations?.[0]?.personal_recommendations.length
        ) {
          this.isLoading = true
          const options = {
            include_fields: `${INCLUDE_FIELDS},categories,default_offer_allow_qty,product_labels,default_discount_effective_start_date,default_discount_effective_end_date,discounted,offers.installment_enabled,offers.max_installment_months,offers.avail_check,offers.old_price,min_qty,offers.seller_id,offers.seller_marketing_name,categories.path_ids`,
            'q[id_in]': idsRecommendations[0]?.personal_recommendations
              .splice(0, 20)
              .map(item => item.product_id)
              .join(','),
          }
          const data = await this.marketStore.getProductById({ options })
          this.isLoading = false
          this.marketStore.personalRecommendationsProducts = data
            .filter(item => item.status === 'active')
            .slice(0, 10)
        }
      }
      this.loadOnce = true
      this.waitingToLoad = false
    },
  },
}
