
export default {
  name: 'MarketLinks',
  props: {
    showOnly: {
      type: String,
      default: 'all',
    },
  },
}
