
import { BANNERS_MANE_PAGE } from '@/constants'
import { useMarketStore } from '~/stores/market'
import startObserverLoading from '@/mixins/startObserverLoading'
import { useCustomer } from '@/stores/customer'

export default {
  name: 'MPLandingCategories',
  mixins: [startObserverLoading],
  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    return {
      marketStore,
      customerStore,
    }
  },
  data() {
    return {
      isLandingCategoriesVisible: false,
    }
  },
  async fetch() {
    if (this.customerStore.isBot) {
      await this.marketStore.getPopularCategories()
      if (this.marketStore.popularCategoriesList.length) {
        this.isLandingCategoriesVisible = true
      }
    }
  },
  computed: {
    BannerId_1() {
      return BANNERS_MANE_PAGE[this.$config.ENVIRONMENT].banners_id.banner_1
    },
    BannerId_2() {
      return BANNERS_MANE_PAGE[this.$config.ENVIRONMENT].banners_id.banner_2
    },
    BannerId_3() {
      return BANNERS_MANE_PAGE[this.$config.ENVIRONMENT].banners_id.banner_3
    },
  },
  async mounted() {
    if (!this.$device.isCrawler) {
      this.startObserverLoading(
        this.$refs.LandingCategories,
        this.showLandingCategories,
      )
    }
  },
  methods: {
    async showLandingCategories() {
      await this.marketStore.getPopularCategories()
      if (this.marketStore.popularCategoriesList.length) {
        this.isLandingCategoriesVisible = true
        await this.initBanners()
        ;(window.globalAmlAds = window.globalAmlAds || []).push(() => {
          globalAml.display(this.BannerId_1)
        })
        ;(window.globalAmlAds = window.globalAmlAds || []).push(() => {
          globalAml.display(this.BannerId_2)
        })
        ;(window.globalAmlAds = window.globalAmlAds || []).push(() => {
          globalAml.display(this.BannerId_3)
        })
      }
    },
    banner(num) {
      return `url('/images/admixer_banner_${num}_${this.$i18n.locale}.webp')`
    },
    initBanners() {
      setTimeout(() => {
        ;(window.globalAmlAds = window.globalAmlAds || []).push(
          BANNERS_MANE_PAGE[this.$config.ENVIRONMENT].init_banner,
        )
      }, 100)
    },
    url(category) {
      return this.localePath({
        name: 'categories-slug',
        params: {
          slug: `${category?.category_id}-${category?.slugged_name}`,
        },
      })
    },
  },
}
