import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1552fda8"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarketLandingIndexSeo: require('/usr/src/app/components/Market/Landing/IndexSeo.vue').default,MarketLandingHeader: require('/usr/src/app/components/Market/Landing/Header.vue').default,AccountCreditLine: require('/usr/src/app/components/Account/CreditLine/CreditLine.vue').default,MarketSingleProductMPViewedProducts: require('/usr/src/app/components/Market/SingleProduct/MPViewedProducts.vue').default,MarketLandingPersonalRecommendations: require('/usr/src/app/components/Market/Landing/PersonalRecommendations.vue').default,MarketLandingRecommended: require('/usr/src/app/components/Market/Landing/Recommended.vue').default,MarketLandingCategories: require('/usr/src/app/components/Market/Landing/Categories.vue').default,MarketLandingMegaDiscounts: require('/usr/src/app/components/Market/Landing/MegaDiscounts.vue').default,MarketLandingTopProducts: require('/usr/src/app/components/Market/Landing/TopProducts.vue').default,MarketLandingNewItems: require('/usr/src/app/components/Market/Landing/NewItems.vue').default,MarketLandingLoyatlyProgram: require('/usr/src/app/components/Market/Landing/LoyatlyProgram.vue').default,MarketLandingApplications: require('/usr/src/app/components/Market/Landing/Applications.vue').default,MarketLandingTopSeoTags: require('/usr/src/app/components/Market/Landing/TopSeoTags.vue').default,LandingFooterBlock: require('/usr/src/app/components/Landing/FooterBlock.vue').default})
