
import { useUiStore } from '@/stores/ui'
export default {
  name: 'MPLandingApplications',
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  computed: {
    mobileImage() {
      return `images/landing/application-screens-mob.webp`
    },

    image() {
      return `images/landing/application-screens.webp`
    },
  },
}
