
import { INCLUDE_FIELDS } from '@/constants'
import startObserverLoading from '@/mixins/startObserverLoading'
import { useMarketStore } from '@/stores/market'
import { mapWritableState, storeToRefs } from 'pinia'
import { useUiStore } from '@/stores/ui'
export default {
  name: 'MPViewedProducts',
  fetchOnServer: false,
  mixins: [startObserverLoading],
  props: {
    isProduct: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
      default: null,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    const { resolution } = storeToRefs(uiStore)
    return { marketStore, uiStore, resolution }
  },
  data() {
    return {
      viewed_products: [],
      loadOnce: false,
      waitingToLoad: true,
    }
  },

  async fetch() {
    //  await this.startLoadingComponent()
  },
  computed: {
    ...mapWritableState(useMarketStore, ['viewedProducts']),
    isMain() {
      const pageRouteName = name => `${name}___${this.$i18n.locale}`
      return [
        pageRouteName('index'),
        pageRouteName('categories-slug'),
        pageRouteName('categories'),
      ].includes(this.$route.name)
    },
    products_by_id() {
      return this.isMain ? this.viewed_products : this.marketStore.productsById
    },
    products() {
      if (
        this.$auth.loggedIn &&
        this.$auth.$storage.getUniversal('user_ext_id')
      ) {
        const viewedProducts = this.viewedProducts || []
        const products = []
        this.products_by_id?.map(item => {
          if (viewedProducts.includes(item.id)) {
            products.push(item)
          }
        })
        return products
      } else {
        const viewedProducts = this.viewedProducts || []
        const products = []
        this.products_by_id?.map(item => {
          if (viewedProducts.includes(item.id)) {
            products.push(item)
          }
        })
        return products
      }
    },
    limit() {
      let limit = 5
      if (this.resolution === 'lg') {
        limit = 4
      }
      if (this.resolution === 'md') {
        limit = 3
      }
      if (this.resolution === 'sm') {
        limit = 4
      }
      return limit
    },
  },
  mounted() {
    this.startObserverLoading(
      this.$refs?.MPViewedRef,
      this.startLoadingComponent,
    )
  },
  methods: {
    async startLoadingComponent() {
      if (this.loadOnce) return
      let product_ids
      if (
        this.$auth.loggedIn &&
        this.$auth.$storage.getUniversal('user_ext_id')
      ) {
        product_ids = await this.marketStore.getViewedProducts({
          customer_id: this.$auth.$storage.getUniversal('user_ext_id'),
          'q[opaque_id]': this.$route?.fullPath,
        })
      } else {
        const customer_id = this.$auth.$storage.getCookie('customer_id')
        if (customer_id) {
          product_ids = await this.marketStore.getViewedProducts({
            customer_id,
            'q[opaque_id]': this.$route?.fullPath,
          })
        }
      }
      const options = {
        include_fields: `${INCLUDE_FIELDS},categories,product_labels,loyalty_cashback,min_qty,discounted,default_offer_allow_qty,offers.installment_enabled,offers.max_installment_months,offers.avail_check,offers.old_price,offers.seller_id,offers.seller_marketing_name,market_lottery_default,market_lottery_premium,offers.product_offer_labels,categories.path_ids`,
        'q[id_in]': product_ids ? product_ids.slice(0, 5).join(',') : '',
        'q[opaque_id]': this.$route?.fullPath,
      }
      if (product_ids?.length > 0) {
        if (this.isMain) {
          this.viewed_products = await this.marketStore.getProductById({
            options,
            local: true,
          })
        } else {
          await this.marketStore.getProductById({
            options,
          })
        }
      }
      this.waitingToLoad = false
      this.loadOnce = true
    },
  },
}
